const sizeInfoStrFilterField = [
  'size',
  'attr_id',
  'attr_name',
  'attr_value_id',
  'attr_value_name',
  'attr_value_name_en',
]

export const getSizeDesc = (info, index, isShowBaseCode, sizeInfoDes) => {
  const result = []
  info.forEach(_ => {
    const item = _.list[index]
    const list = []
    for (const k in item) {
      if (sizeInfoStrFilterField.includes(k)) continue
      const val = item[k].replace(/(cm|inch)/g, '').trim()
      if (val && val !== '/') {
        list.push({ k: k.trim(), val: item[k].trim() })
      }
    }
    if (list.length) {
      result.push({
        name: _.name,
        list
      })
    }
  })
  if (isShowBaseCode.value) {
    let baseSizeSortMap = sizeInfoDes.value.basicAttribute.base_atte_sort || null
    if (baseSizeSortMap) {
      for (let item of result) {
        item.list = item.list.sort((a, b) => baseSizeSortMap[a.k] - baseSizeSortMap[b.k])
      }
    }
  }
  return result
}

/**
 * 格式化身高信息
 */
export const formatHeightSize = (h, type) => {
  if (!h.length) return ''
  try {
    let numberStr = h.replace(/(cm|inch)/g, '').trim()
    let numberArr = numberStr.split('-')
    if (isNaN(numberArr[0])) return ''
    if (numberArr.length === 2) {
      // 104.3-110.3 cm
      return `(${Number(numberArr[0]).toFixed()}-${Number(numberArr[1]).toFixed()}${type})`
    } else {
      // 104.3cm
      let singleNumber = numberArr[0].split(type)
      return `(${Number(singleNumber[0]).toFixed()}${type})`
    }
  } catch (error) {
    return ''
  }
}

export const getAbSizeGroup = (abParam, sizeGroupList) => {
  if (!abParam || abParam == 'all_off' || !sizeGroupList?.length) return []
  sizeGroupList = sizeGroupList.sort((a, b) => Date.parse(b?.lastUpdateTime) - Date.parse(a?.lastUpdateTime)).sort((a, b) => b?.orderValue - a?.orderValue)
  const result = new Map()
  sizeGroupList.forEach(item => {
    const { displayDesc, isCurrentGroup, isManualGroup } = item || {}
    const group = result.get(displayDesc)
    // 主商品不参与过滤
    if (isCurrentGroup) {
      result.set(displayDesc, item)
      return
    }
    if (group) {
      // 优先展示人工搭配
      const preIsManualGroup = group?.isManualGroup
      abParam == 'all_on' && isManualGroup && !preIsManualGroup && result.set(displayDesc, item)
    }else {
      if (abParam == 'all_on') {
        result.set(displayDesc, item)
        return
      }
      if (abParam == 'only_cv') {
        !isManualGroup && result.set(displayDesc, item)
        return
      }
      isManualGroup && result.set(displayDesc, item)
    }
  })
  return [...result.values()].slice(0, 5)
}
